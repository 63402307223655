<template>
  <div class="pad-home">
    <HeaderBar title="商家信息" :showLeft="true"></HeaderBar>
    <div class="advertise-container">
      <iframe :src="adUrl" frameborder="0" width="100%" height="100%"></iframe>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/headerbar/headerbar";
import { getAdvertiseDetail } from "@/service/";

export default {
  name: "advertiseDetail",
  components: {
    HeaderBar
  },
  data() {
    return {
      adUrl: ""
    };
  },
  computed: {
    adId() {
      return this.$route.params.id;
    }
  },
  methods: {
    async getAdvertise(id) {
      let res = await getAdvertiseDetail(id);
      if (res.code == 1) {
        this.adUrl = res.data.ADLink;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getAdvertise(this.adId);
    });
  }
};
</script>

<style scoped lang="less">
.pad-home {
  padding-top: 94px;
}
.advertise-container {
  width: 100vw;
  height: 100vh;
}
</style>
